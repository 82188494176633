@use 'global' as *;

.p-hamburger {
    position: relative;
    background-color: $white;
    width: 100%;
    height: 100%;
    border-radius: rem(3);
}

.p-hamburger span {
    position: absolute;
    display: inline-block;
    width: rem(20);
    height: rem(3);
    background-color: $black;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s;
}

.p-hamburger span:nth-child(1) {
    top: rem(3);
}

.p-hamburger span:nth-child(2) {
    top: rem(10);
}

.p-hamburger span:nth-child(3) {
    top: rem(17);
}

.p-hamburger.is-active span:nth-child(1) {
    top: rem(10);
    transform: translateX(-50%) rotate(45deg);
}

.p-hamburger.is-active span:nth-child(2) {
    opacity: 0;
    transform: translateX(0%);
}

.p-hamburger.is-active span:nth-child(3) {
    top: rem(10);
    transform: translateX(-50%) rotate(-45deg);
}