@charset "UTF-8";
body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
}

.u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-desktop {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .u-mobile {
    display: none;
  }
}

html.is-fixed {
  overflow: hidden;
}

/* リキッドレイアウト対応 */
html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.1428571429vw;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 16px;
  }
}

/* pcの電話番号発信対応 */
@media screen and (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  a:hover {
    opacity: 0.8;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

picture, button {
  display: block;
}

.c-btn {
  padding: 0.625rem 2.5rem;
  background-color: black;
  color: yellow;
}

.c-title {
  font-size: 3.1875rem;
  letter-spacing: 0.16em;
  line-height: 1.2;
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  color: #0094d2;
}
@media screen and (min-width: 768px) {
  .c-title {
    font-size: 5rem;
  }
}

.c-title--white {
  color: #fff;
}

.c-title--center {
  text-align: center;
}

.l-footer {
  margin-top: 3.75rem;
}

.l-inner {
  margin: 0 auto;
  max-width: 33.75rem;
  width: 100%;
  height: inherit;
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (min-width: 768px) {
  .l-inner {
    max-width: 1450px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.p-charger {
  padding-top: 2.8125rem;
  padding-bottom: 6.25rem;
}
@media screen and (min-width: 768px) {
  .p-charger {
    padding-top: 5.625rem;
    padding-bottom: 12.5rem;
  }
}

@media screen and (min-width: 768px) {
  .p-charger__wrap {
    display: flex;
    align-items: flex-end;
  }
}

.p-charger__subtitle {
  max-width: 17.625rem;
  margin: 0 auto;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-charger__subtitle {
    max-width: 27.8125rem;
    margin-top: 2.5rem;
    margin-left: 0;
  }
}

.p-charger__items {
  display: grid;
  gap: 3.125rem;
  margin-top: 2.8125rem;
}
@media screen and (min-width: 768px) {
  .p-charger__items {
    grid-template-columns: repeat(3, 1fr);
    gap: 5.625rem;
    margin-left: auto;
  }
}

.p-charger__img {
  margin: 0 auto;
  max-width: 8.375rem;
}
@media screen and (min-width: 768px) {
  .p-charger__img {
    max-width: 8.5625rem;
  }
}

.p-charger__item-title {
  text-align: center;
  font-size: 1.3125rem;
  font-weight: bold;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-charger__item-title {
    font-size: 1.375rem;
    margin-top: 3.75rem;
  }
}

.p-charger__item-subtitle {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.06em;
  line-height: 1.1333333333;
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .p-charger__item-subtitle {
    font-size: 1rem;
    margin-top: 0.625rem;
  }
}

.p-charger__item-text {
  max-width: 12.1875rem;
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin: 0 auto;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-charger__item-text {
    font-size: 0.875rem;
    margin-top: 2.125rem;
    max-width: 12.5rem;
  }
}

.p-contact {
  background: #086aac;
  padding-top: 5rem;
  padding-bottom: 4.6875rem;
}
@media screen and (min-width: 768px) {
  .p-contact {
    padding-top: 7rem;
    padding-bottom: 6.25rem;
  }
}

.p-contact__inner.l-inner {
  padding: 0 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-contact__inner.l-inner {
    display: flex;
    align-items: flex-start;
    max-width: 1315px;
  }
}

@media screen and (min-width: 768px) {
  .p-contact__head {
    width: 39%;
  }
}

.p-contact__subtitle {
  max-width: 13.125rem;
  margin-top: 1.5625rem;
}
@media screen and (min-width: 768px) {
  .p-contact__subtitle {
    max-width: 20rem;
    margin-top: 1.875rem;
  }
}

.p-contact__form {
  margin-top: 3.125rem;
}
@media screen and (min-width: 768px) {
  .p-contact__form {
    margin-top: -1.875rem;
    width: 61%;
  }
}

.p-conv {
  background: url(../images/conv_bg_sp.jpg) center center/cover no-repeat;
  padding-top: 3.4375rem;
  padding-bottom: 6.25rem;
}
@media screen and (min-width: 768px) {
  .p-conv {
    background: url(../images/conv_bg.jpg) center center/cover no-repeat;
    padding: 5.625rem 0;
  }
}

@media screen and (min-width: 768px) {
  .p-conv__inner.l-inner {
    display: flex;
    align-items: center;
    max-width: 1315px;
  }
}

@media screen and (min-width: 768px) {
  .p-conv__title {
    width: 27.5rem;
  }
}

.p-conv__btns {
  display: grid;
  gap: 1.5625rem;
  margin-top: 2.8125rem;
}
@media screen and (min-width: 768px) {
  .p-conv__btns {
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 27.5rem);
    gap: 3.125rem;
    padding-left: 3.125rem;
  }
}

.p-conv__btn a {
  display: inline-block;
  width: 100%;
  text-align: center;
  background-color: #fff;
  display: inline-block;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 1.0625rem 0;
}
@media screen and (min-width: 768px) {
  .p-conv__btn a {
    border-radius: 0.625rem;
    font-size: 1.5rem;
    padding: 1.375rem 0;
  }
}

.p-conv__btn span {
  display: block;
  font-size: 0.8125rem;
}
@media screen and (min-width: 768px) {
  .p-conv__btn span {
    font-size: 1rem;
    margin-bottom: 0.625rem;
  }
}

.p-drawer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(89, 87, 87, 0.8);
  padding-top: 3.125rem;
  display: none;
  max-width: 31.25rem;
}

.p-drawer__inner {
  max-width: 17.1875rem;
  margin: 0 auto;
}

.p-drawer__link {
  border-bottom: 1px solid #fff;
}

.p-drawer__link a {
  width: 100%;
  height: 100%;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 1.875rem 0;
}

.p-footer {
  padding-bottom: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-footer {
    padding-bottom: 4.0625rem;
  }
}

@media screen and (min-width: 768px) {
  .p-footer__inner.l-inner {
    position: relative;
    max-width: 860px;
  }
}

.p-footer__head {
  display: flex;
  align-items: center;
}

.p-footer__logo {
  width: 6.6875rem;
}
@media screen and (min-width: 768px) {
  .p-footer__logo {
    position: absolute;
    width: 5.9375rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.p-footer__info {
  width: calc(100% - 6.6875rem);
  padding-left: 1.1875rem;
}
@media screen and (min-width: 768px) {
  .p-footer__info {
    padding-left: 8.75rem;
  }
}

.p-footer__title {
  font-size: 1.1875rem;
  font-weight: bold;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-footer__title {
    font-size: 1.5rem;
  }
}

.p-footer__address {
  font-size: 0.875rem;
  line-height: 1.5357142857;
  letter-spacing: 0.02em;
  margin-top: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-footer__address {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.p-footer__tel {
  font-size: 0.875rem;
  line-height: 1.5357142857;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-footer__tel {
    display: inline-block;
  }
}

.p-footer__fax {
  font-size: 0.875rem;
  line-height: 1.5357142857;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-footer__fax {
    display: inline-flex;
    margin-left: 1.25rem;
  }
}

.p-footer__nav {
  max-width: 14.375rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 768px) {
  .p-footer__nav {
    max-width: unset;
    margin-top: 1.25rem;
    padding-left: 8.75rem;
  }
}

@media screen and (min-width: 768px) {
  .p-footer__links {
    display: flex;
    margin-left: -0.375rem;
  }
}

.p-footer__link {
  border-bottom: 1px solid #9fa0a0;
}
@media screen and (min-width: 768px) {
  .p-footer__link {
    border-bottom: none;
  }
}

.p-footer__link a {
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  padding: 1.125rem 0;
}
@media screen and (min-width: 768px) {
  .p-footer__link a {
    padding: 0 0.375rem;
  }
}

.p-footer__copy {
  text-align: center;
  font-size: 0.8125rem;
  letter-spacing: 0.02em;
  margin-top: 1.125rem;
}
@media screen and (min-width: 768px) {
  .p-footer__copy {
    position: absolute;
    font-size: 0.875rem;
    margin-top: 0;
    left: 0;
    top: 0;
    margin-left: calc(50% - 50vw + 2.5rem);
    transform: rotate(90deg);
    transform-origin: top left;
  }
}

.p-form dl {
  margin-top: 1.25rem;
}
@media screen and (min-width: 768px) {
  .p-form dl {
    margin-top: 1.875rem;
  }
}

@media screen and (min-width: 768px) {
  .p-form__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.125rem;
  }
}

.p-form dt {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.02em;
}

.p-form dd {
  margin-top: 0.625rem;
}

.p-form dd input {
  display: inline-block;
  font-size: 1rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.1875rem;
  padding: 0.5rem 0.5em;
}
@media screen and (min-width: 768px) {
  .p-form dd input {
    border-radius: 0.375rem;
  }
}

.p-form dd textarea {
  display: inline-block;
  font-size: 1rem;
  width: 100%;
  height: 9.375rem;
  background-color: #fff;
  border-radius: 0.1875rem;
  padding: 0.5rem 0.5em;
}
@media screen and (min-width: 768px) {
  .p-form dd textarea {
    height: 11.25rem;
    border-radius: 0.375rem;
  }
}

#form_submit {
  max-width: 7.5rem;
  margin: 0 auto;
  margin-top: 1.4375rem;
}
@media screen and (min-width: 768px) {
  #form_submit {
    max-width: 9.375rem;
    margin-top: 3.125rem;
  }
}

#form_submit input {
  display: inline-block;
  width: 100%;
  height: 2.125rem;
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.02em;
  border: 1px solid #fff;
  transition: all 0.3s;
}
@media screen and (min-width: 768px) {
  #form_submit input {
    font-size: 1rem;
    height: 2.5rem;
  }
}

#form_submit_button:hover {
  background-color: #fff;
  color: #086aac;
}

.error_blank,
.error_format,
.error_match {
  display: block;
  color: #ff0000;
  margin-top: 5px;
}

/* -- loading -------------------------------------------------------------------------------- */
div.loading-layer {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10000;
}

span.loading {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load-circle 1s linear infinite;
  animation: load-circle 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
}

@-webkit-keyframes load-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.p-globalNav {
  background-color: #fff;
  height: 3.125rem;
  width: 100%;
  border-radius: 1rem;
}

.p-globalNav__lists {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.p-globalNav__list {
  height: 100%;
}

.p-globalNav__list a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1.0625rem;
}

.p-globalNav__list a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.p-hamburger {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 0.1875rem;
}

.p-hamburger span {
  position: absolute;
  display: inline-block;
  width: 1.25rem;
  height: 0.1875rem;
  background-color: #231815;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s;
}

.p-hamburger span:nth-child(1) {
  top: 0.1875rem;
}

.p-hamburger span:nth-child(2) {
  top: 0.625rem;
}

.p-hamburger span:nth-child(3) {
  top: 1.0625rem;
}

.p-hamburger.is-active span:nth-child(1) {
  top: 0.625rem;
  transform: translateX(-50%) rotate(45deg);
}

.p-hamburger.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(0%);
}

.p-hamburger.is-active span:nth-child(3) {
  top: 0.625rem;
  transform: translateX(-50%) rotate(-45deg);
}

.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.75rem;
  z-index: 999;
}
@media screen and (min-width: 1280px) {
  .p-header {
    height: 9.375rem;
  }
}

.p-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-header__inner.l-inner {
  padding: 0 1.125rem;
}
@media screen and (min-width: 1280px) {
  .p-header__inner.l-inner {
    max-width: unset;
    padding: 0 2.5rem;
  }
}

.p-header__logo {
  width: 16.875rem;
}
@media screen and (min-width: 768px) {
  .p-header__logo {
    max-width: 30.4375rem;
    width: 30%;
  }
}

.p-header__globalNav {
  display: none;
}
@media screen and (min-width: 1280px) {
  .p-header__globalNav {
    display: block;
    width: 46.875rem;
    margin-left: auto;
  }
}

.p-header__hamburger {
  width: 1.6875rem;
  height: 1.5rem;
  margin-left: auto;
  z-index: 2;
}
@media screen and (min-width: 1280px) {
  .p-header__hamburger {
    display: none;
  }
}

.p-header__drawer {
  z-index: 1;
}
@media screen and (min-width: 1280px) {
  .p-header__drawer {
    display: none;
  }
}

.p-mv {
  width: 100%;
}

.p-scene {
  background: url(../images/scene_bg_sp.jpg) center center/cover no-repeat;
  padding-top: 5rem;
  padding-bottom: 2.375rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .p-scene {
    background: url(../images/scene_bg.jpg) center center/cover no-repeat;
    padding-top: 6.25rem;
    padding-bottom: 5.625rem;
  }
}

.p-scene__subtitle {
  text-align: center;
  font-size: 1.0625rem;
  font-weight: bold;
  color: #fff;
}

.p-scene__text {
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.6785714286;
  margin-top: 0.9375rem;
}

.p-scene__slider {
  margin-top: 2.8125rem;
}
@media screen and (min-width: 768px) {
  .p-scene__slider {
    max-width: 46.25rem;
    margin: 0 auto;
    margin-top: 3.125rem;
  }
}

.slick-slide .u-desktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .slick-slide .u-desktop {
    display: block;
  }
}

.slick-slide .u-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .slick-slide .u-mobile {
    display: none;
  }
}

.slide-arrow {
  position: absolute;
  width: 0.6875rem;
  height: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .slide-arrow {
    width: 0.875rem;
    height: 1.875rem;
  }
}

.prev-arrow {
  background: url(../images/prev_arrow.png) center center/contain no-repeat;
  left: -1.25rem;
}
@media screen and (min-width: 768px) {
  .prev-arrow {
    left: 1.25rem;
  }
}

.next-arrow {
  background: url(../images/next_arrow.png) center center/contain no-repeat;
  right: -1.25rem;
}
@media screen and (min-width: 768px) {
  .next-arrow {
    right: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .p-scene__slider .slick-slide > div {
    margin: 0 3.75rem;
  }
}

.p-spec {
  padding-top: 3.75rem;
  padding-bottom: 4.5rem;
}
@media screen and (min-width: 768px) {
  .p-spec {
    padding-top: 6.25rem;
    padding-bottom: 9.0625rem;
  }
}

.p-spec__logo {
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-spec__logo {
    max-width: 30.4375rem;
  }
}

.p-spec__head {
  margin-top: 2.5rem;
}
@media screen and (min-width: 768px) {
  .p-spec__head {
    display: flex;
    align-items: center;
  }
}

.p-spec__img {
  max-width: 17.0625rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .p-spec__img {
    max-width: calc(100% - 56.25rem);
  }
}

.p-spec__info {
  margin-top: 2.25rem;
}
@media screen and (min-width: 768px) {
  .p-spec__info {
    width: 52.5rem;
    margin-left: 3.75rem;
    margin-top: 0;
  }
}

.p-spec__info dl {
  margin-top: 0.625rem;
}
@media screen and (min-width: 768px) {
  .p-spec__info dl {
    display: flex;
  }
}

.p-spec__info dt {
  font-size: 0.9375rem;
  font-weight: bold;
  letter-spacing: 0.02em;
}
@media screen and (min-width: 768px) {
  .p-spec__info dt {
    line-height: 1.7333333333;
    width: 10.625rem;
    font-size: 1.125rem;
  }
}

.p-spec__info dd {
  font-size: 0.9375rem;
  letter-spacing: 0.02em;
  line-height: 1.7333333333;
  margin-top: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .p-spec__info dd {
    width: calc(100% - 10.625rem);
    font-size: 1.125rem;
    font-feature-settings: "palt";
    margin-top: 0;
  }
}

.p-spec__lists {
  display: grid;
  gap: 2.9375rem;
  max-width: 17.5rem;
  margin: 0 auto;
  margin-top: 4.0625rem;
}
@media screen and (min-width: 768px) {
  .p-spec__lists {
    margin-top: 6.25rem;
    max-width: unset;
    gap: 6.25rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

.p-toTop {
  position: fixed;
  bottom: 3.75rem;
  right: 2.5rem;
  transform: rotate(90deg);
  transform-origin: bottom right;
}

.p-toTop__text {
  font-size: 0.875rem;
}