@use 'global' as *;

.p-drawer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba($color: #595757, $alpha: 0.8);
    padding-top: rem(50);
    display: none;
    max-width: rem(500);
}

.p-drawer__inner {
    max-width: rem(275);
    margin: 0 auto;
}

.p-drawer__nav {

}

.p-drawer__links {

}

.p-drawer__link {
    border-bottom: 1px solid $white;
}

.p-drawer__link a {
    width: 100%;
    height: 100%;
    display: inline-block;
    font-size: rem(16);
    font-weight: bold;
    color: $white;
    padding: rem(30) 0;
}