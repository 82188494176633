@use 'global' as *;

.p-globalNav {
    background-color: $white;
    height: rem(50);
    width: 100%;
    border-radius: rem(16);
}

.p-globalNav__lists {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.p-globalNav__list {
    height: 100%;

}

.p-globalNav__list a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 rem(17);
}

.p-globalNav__list a:hover {
    background-color: rgba($color: #000, $alpha: 0.1);
}