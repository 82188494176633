@use 'global' as *;

.p-footer {
    padding-bottom: rem(30);

    @include mq(md) {
        padding-bottom: rem(65);
    }
}

.p-footer__inner.l-inner {
    @include mq(md) {
        position: relative;
        max-width: 860px;
    }
}

.p-footer__head {
    display: flex;
    align-items: center;
}

.p-footer__logo {
    width: rem(107);

    @include mq(md) {
        position: absolute;
        width: rem(95);
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.p-footer__info {
    width: calc(100% - #{rem(107)});
    padding-left: rem(19);

    @include mq(md) {
        padding-left: rem(140);
    }
}

.p-footer__title {
    font-size: rem(19);
    font-weight: bold;
    letter-spacing: 0.02em;

    @include mq(md) {
        font-size: rem(24);
    }
}

.p-footer__address {
    font-size: rem(14);
    line-height: calc(43/28);
    letter-spacing: 0.02em;
    margin-top: rem(10);

    @include mq(md) {
        font-size: rem(16);
        margin-top: rem(16);
    }
}

.p-footer__tel {
    font-size: rem(14);
    line-height: calc(43/28);
    letter-spacing: 0.02em;

    @include mq(md) {
        display: inline-block;
    }
}

.p-footer__fax {
    font-size: rem(14);
    line-height: calc(43/28);
    letter-spacing: 0.02em;

    @include mq(md) {
        display: inline-flex;
        margin-left: rem(20);
    }
}

.p-footer__nav {
    max-width: rem(230);
    margin-top: rem(30);

    @include mq(md) {
        max-width: unset;
        margin-top: rem(20);
        padding-left: rem(140);
    }
}

.p-footer__links {
    @include mq(md) {
        display: flex;
        margin-left: rem(-6);
    }
}

.p-footer__link {
    border-bottom: 1px solid #9fa0a0;

    @include mq(md) {
        border-bottom: none;
    }
}

.p-footer__link a {
    display: inline-block;
    font-size: rem(15);
    font-weight: bold;
    letter-spacing: 0.02em;
    padding: rem(18) 0;

    @include mq(md) {
        padding: 0 rem(6);
    }
}

.p-footer__copy {
    text-align: center;
    font-size: rem(13);
    letter-spacing: 0.02em;
    margin-top: rem(18);

    @include mq(md) {
        position: absolute;
        font-size: rem(14);
        margin-top: 0;
        left: 0;
        top: 0;
        margin-left: calc(50% - 50vw + #{rem(40)}); 
        transform: rotate(90deg);
        transform-origin: top left;
    }
}