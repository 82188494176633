@use 'global' as *;

.p-contact {
    background: #086aac;
    padding-top: rem(80);
    padding-bottom: rem(75);

    @include mq(md){
        padding-top: rem(112);
        padding-bottom: rem(100);
    }
}

.p-contact__inner.l-inner {
    padding: 0 rem(40);

    @include mq(md) {
        display: flex;
        align-items: flex-start;
        max-width: 1315px;
    }
}

.p-contact__head {
    @include mq(md) {
        width: 39%;
    }
}

.p-contact__title {

}

.p-contact__subtitle {
    max-width: rem(210);
    margin-top: rem(25);

    @include mq(md) {
        max-width: rem(320);
        margin-top: rem(30);
    }
}

.p-contact__form {
    margin-top: rem(50);

    @include mq(md) {
        margin-top: rem(-30);
        width: 61%;
    }
}