@use 'global' as *;

.c-title {
    font-size: rem(51);
    letter-spacing: 0.16em;
    line-height: calc(6/5);
    font-family: $sub-font;
    font-weight: 400;
    color: #0094d2;

    @include mq(md) {
        font-size: rem(80);
    }
}

.c-title--white {
    color: $white;
}

.c-title--center {
    text-align: center;
}