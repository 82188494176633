/* リキッドレイアウト対応 */
@use "global" as *;

// sp
html {
  //~375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }

  //375px~787px
  font-size: 16px;

  @include mq("md") {
    font-size: vw(strip-unit($l-inner), 16);
  }

  //inner~max-screen
  @media (min-width: $l-inner) {
    font-size: 16px;
  }
}

//pc
// html {
//   font-size: 16px;

//   //~375px
//   @include mq("xl") {
//     // font-size: vw(strip-unit($l-inner), 16);
//     font-size: vw(1280, 16);
//   }

//   @include mq(md) {
//     font-size: 16px;
//   }

//   @media (max-width: 375px) {
//     font-size: vw(375, 16);
//   }

// }

/* pcの電話番号発信対応 */
a[href^="tel:"] {
  @include mq("md") {
    pointer-events: none;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
  @include mq("md") {
    &:hover {
      opacity: 0.8;
    }
  }
}
