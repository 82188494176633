@use "global" as *;

// インナー
.l-inner {
	margin: 0 auto;
	max-width: rem(540); //状況によって変える必要があるかも
	width: 100%;
	height: inherit;
	padding-right: $padding-sp;
	padding-left: $padding-sp;
	@include mq(md) {
		max-width: $l-inner + strip-unit($padding-pc) * 2;
		padding-right: $padding-pc;
		padding-left: $padding-pc;
	}
}
