@use 'global' as *;

.p-charger {
    padding-top: rem(45);
    padding-bottom: rem(100);
    @include mq(md) {
        padding-top: rem(90);
        padding-bottom: rem(200);
    }
}

.p-charger__inner {

}

.p-charger__wrap {
    @include mq(md) {
        display: flex;
        align-items: flex-end;
    }
}

.p-charger__head {

}

.p-charger__title {

}

.p-charger__subtitle {
    max-width: rem(282);
    margin: 0 auto;
    margin-top: rem(30);

    @include mq(md) {
        max-width: rem(445);
        margin-top: rem(40);
        margin-left: 0;
    }
}

.p-charger__items {
    display: grid;
    gap: rem(50);
    margin-top: rem(45);

    @include mq(md) {
        grid-template-columns: repeat(3,1fr);
        gap: rem(90);
        margin-left: auto;
    }
}

.p-charger__item {

}

.p-charger__img {
    margin: 0 auto;
    max-width: rem(134);

    @include mq(md) {
        max-width: rem(137);
    }
}

.p-charger__item-title {
    text-align: center;
    font-size: rem(21);
    font-weight: bold;
    margin-top: rem(30);

    @include mq(md) {
        font-size: rem(22);
        margin-top: rem(60);
    }
}

.p-charger__item-subtitle {
    text-align: center;
    font-size: rem(15);
    letter-spacing: 0.06em;
    line-height: calc(34/30);
    margin-top: rem(15);
    
    @include mq(md) {
        font-size: rem(16);
        margin-top: rem(10);
    }
}

.p-charger__item-text {
    max-width: rem(195);
    text-align: center;
    font-size: rem(15);
    line-height: 1.5;
    margin: 0 auto;
    margin-top: rem(30);

    @include mq(md) {
        font-size: rem(14);
        margin-top: rem(34);
        max-width: rem(200);
    }
}