@use 'global' as *;

.p-spec {
    padding-top: rem(60);
    padding-bottom: rem(72);

    @include mq(md) {
        padding-top: rem(100);
        padding-bottom: rem(145);
    }
}

.p-spec__inner {

}

.p-spec__logo {
    margin: 0 auto;
    @include mq(md) {
        max-width: rem(487);
    }
}

.p-spec__head {
    margin-top: rem(40);

    @include mq(md) {
        display: flex;
        align-items: center;
    }
}

.p-spec__img {
    max-width: rem(273);
    margin: 0 auto;

    @include mq(md) {
        max-width: calc(100% - #{rem(900)}); 
    }
}

.p-spec__info {
    margin-top: rem(36);

    @include mq(md) {
        width: rem(840);
        margin-left: rem(60);
        margin-top: 0;
    }
}

.p-spec__info dl {
    margin-top: rem(10);

    @include mq(md) {
        display: flex;
    }
}

.p-spec__info dt {
    font-size: rem(15);
    font-weight: bold;
    letter-spacing:0.02em;

    @include mq(md) {
        line-height: calc(52/30);
        width: rem(170);
        font-size: rem(18);
    }
}

.p-spec__info dd {
    font-size: rem(15);
    letter-spacing:0.02em;
    line-height: calc(52/30);
    margin-top: rem(5);

    @include mq(md) {
        width: calc(100% - #{rem(170)});
        font-size: rem(18);
        font-feature-settings: "palt";
        margin-top: 0;
    }
}

.p-spec__lists {
    display: grid;
    gap: rem(47);
    max-width: rem(280);
    margin: 0 auto;
    margin-top: rem(65);

    @include mq(md) {
        margin-top: rem(100);
        max-width: unset;
        gap: rem(100);
        grid-template-columns: repeat(3,1fr);
    }
}

.p-spec__list {

}