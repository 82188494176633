@use 'global' as *;

.p-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(60);
    z-index: 999;

    @include mq(xl) {
        height: rem(150);
    }
}

.p-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-header__inner.l-inner {
    padding: 0 rem(18);
    @include mq(xl) {
        max-width: unset;
        padding: 0 rem(40);
    }
}

.p-header__logo {
    width: rem(270);

    @include mq(md) {
        max-width: rem(487);
        width: 30%;
    }
}

.p-header__logo a {

}

.p-header__globalNav {
    display: none;

    @include mq(xl) {
        display: block;
        width: rem(750);
        margin-left: auto;
    }
}

.p-header__hamburger {
    width: rem(27);
    height: rem(24);
    margin-left: auto;
    z-index: 2;

    @include mq(xl) {
        display: none;
    }
}

.p-header__drawer {
    z-index: 1;
    @include mq(xl) {
        display: none;
    }
}