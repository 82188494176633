@use 'global' as *;

.p-conv {
    background: url(../images/conv_bg_sp.jpg) center center / cover no-repeat;
    padding-top: rem(55);
    padding-bottom: rem(100);
    @include mq(md) {
        background: url(../images/conv_bg.jpg) center center / cover no-repeat;
        padding: rem(90) 0;
    }
}

.p-conv__inner.l-inner {
    @include mq(md) {
        display: flex;
        align-items: center;
        max-width: 1315px;
    }
}

.p-conv__title {
    @include mq(md) {
        width: rem(440);
    }
}

.p-conv__btns {
    display: grid;
    gap: rem(25);
    margin-top: rem(45);

    @include mq(md) {
        grid-template-columns: repeat(2,1fr);
        width: calc(100% - #{rem(440)});
        gap: rem(50);
        padding-left: rem(50);
    }
}

.p-conv__btn {

}

.p-conv__btn a {
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: $white;
    display: inline-block;
    border-radius: rem(4);
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: rem(17) 0;

    @include mq(md) {
        border-radius: rem(10);
        font-size: rem(24);
        padding: rem(22) 0;
    }
}

.p-conv__btn span {
    display: block;
    font-size: rem(13);

    @include mq(md) {
        font-size: rem(16);
        margin-bottom: rem(10);
    }
}