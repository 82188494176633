@use 'global' as *;

.p-form {

}

.p-form dl {

}

.p-form dl {
    margin-top: rem(20);

    @include mq(md) {
        margin-top: rem(30);
    }
}

.p-form__wrap {
    @include mq(md) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: rem(50);
    }
}

.p-form dt {
    color: $white;
    font-size: rem(16);
    letter-spacing: 0.02em;
}

.p-form dd {
    margin-top: rem(10);
}

.p-form dd input {
    display: inline-block;
    font-size: rem(16);
    width: 100%;
    background-color: $white;
    border-radius: rem(3);
    padding: rem(8) 0.5em;

    @include mq(md) {
        border-radius: rem(6);
    }
}

.p-form dd textarea {
    display: inline-block;
    font-size: rem(16);
    width: 100%;
    height: rem(150);
    background-color: $white;
    border-radius: rem(3);
    padding: rem(8) 0.5em;
    @include mq(md) {
        height: rem(180);
        border-radius: rem(6);
    }
}

#form_submit {
    max-width: rem(120);
    margin: 0 auto;
    margin-top: rem(23);

    @include mq(md) {
        max-width: rem(150);
        margin-top: rem(50);
    }
}

#form_submit input {
    display: inline-block;
    width: 100%;
    height: rem(34);
    color: $white;
    font-size: rem(13);
    letter-spacing: 0.02em;
    border: 1px solid $white;
    transition: all 0.3s;

    @include mq(md) {
        font-size: rem(16);
        height: rem(40);
    }
}

#form_submit_button:hover {
    background-color: $white;
    color: #086aac;
}

.error_blank,
.error_format,
.error_match {
	display: block;
	color: #ff0000;
	margin-top: 5px;
}

/* -- loading -------------------------------------------------------------------------------- */

div.loading-layer {
	width: 100vw;
	height: 100vh;
	background: rgba( 0, 0, 0, 0.7 );
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 10000;
}

span.loading {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border-top: 5px solid rgba( 255, 255, 255, 0.2 );
	border-right: 5px solid rgba( 255, 255, 255, 0.2 );
	border-bottom: 5px solid rgba( 255, 255, 255, 0.2 );
	border-left: 5px solid #ffffff;
	-webkit-transform: translateZ( 0 );
	-ms-transform: translateZ( 0 );
	transform: translateZ( 0 );
	-webkit-animation: load-circle 1.0s linear infinite;
	animation: load-circle 1.0s linear infinite;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -30px;
	margin-left: -30px;
}

@-webkit-keyframes load-circle {
	0% {
		-webkit-transform: rotate( 0deg );
		transform: rotate( 0deg );
	}
	100% {
		-webkit-transform: rotate( 360deg );
		transform: rotate( 360deg );
	}
}

@keyframes load-circle {
	0% {
		-webkit-transform: rotate( 0deg );
		transform: rotate( 0deg );
	}
	100% {
		-webkit-transform: rotate( 360deg );
		transform: rotate( 360deg );
	}
}


