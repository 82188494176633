@use 'global' as *;

.p-scene {
    background: url(../images/scene_bg_sp.jpg) center center / cover no-repeat;
    padding-top: rem(80);
    padding-bottom: rem(38);
    overflow: hidden;

    @include mq(md) {
        background: url(../images/scene_bg.jpg) center center / cover no-repeat;
        padding-top: rem(100);
        padding-bottom: rem(90);
    }
}

.p-scene__inner {

}

.p-scene__title {

}

.p-scene__subtitle {
    text-align: center;
    font-size: rem(17);
    font-weight: bold;
    color: $white;
}

.p-scene__text {
    color: $white;
    text-align: center;
    font-size: rem(14);
    line-height: calc(47/28);
    margin-top: rem(15);
}

.p-scene__slider {
    margin-top: rem(45);

    @include mq(md) {
        max-width: rem(740);
        margin: 0 auto;
        margin-top: rem(50);
    }

}

.p-scene__img {
    
}

.slick-slide .u-desktop {
    display: none;

    @include mq(md) {
        display: block;
    }
}

.slick-slide .u-mobile {
    display: block;
   
    @include mq(md) {
        display: none;
    }
}

.slide-arrow {
    position: absolute;
    width: rem(11);
    height: rem(24);
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    @include mq(md) {
        width: rem(14);
        height: rem(30);
    }
}

.prev-arrow {
    background: url(../images/prev_arrow.png) center center / contain no-repeat;
    left: rem(-20);

    @include mq(md) {
        left: rem(20);
    }
}

.next-arrow {
    background: url(../images/next_arrow.png) center center / contain no-repeat;
    right: rem(-20);

    @include mq(md) {
        right: rem(20);
    }
}

.p-scene__slider .slick-slide > div {
    @include mq(md) {
        margin: 0 rem(60);
    }
}