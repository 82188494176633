@use 'global' as *;

.p-toTop {
    position: fixed;
    bottom: rem(60);
    right: rem(40);
    transform: rotate(90deg);
    transform-origin: bottom right;
}

.p-toTop__text {
    font-size: rem(14);
}